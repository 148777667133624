import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import 'lazysizes';
import * as Sentry from '@sentry/browser';
import 'normalize.css/normalize.css';
import 'react-dates/lib/css/_datepicker.css';
import {getApiConfig} from './config/configProvider';

import registerServiceWorker from './registerServiceWorker';
import Root from './client/Root/Root.container';
import configureStore from './store/config.store';
import {GoogleAnalyticsHelper} from './helpers/googleAnalytics.helper';
import {setServerConfig} from './config/configProvider';
import {ERRORS, SENTRY_ALLOWED_URLS, URL} from './constants/constants';

const init = preloadedState => {
  const store = configureStore(preloadedState.store);
  if (preloadedState) {
    setServerConfig(preloadedState.CONFIG_JS, false, preloadedState.release);
  }

  GoogleAnalyticsHelper.initialize();
  const config = getApiConfig();
  const isDev = window.location.host.indexOf(URL.JJS_PROD) === -1;
  Sentry.init({
    dsn: config && config.sentryUrl,
    ignoreErrors: [
      ERRORS.WEB_SOCKET,
      ERRORS.TIMEOUT,
      ERRORS.NOT_AUTHORIZED,
      ERRORS.NON_ERROR_PROMISE,
    ],
    environment: isDev ? 'development' : 'production',
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    allowUrls: [SENTRY_ALLOWED_URLS],
  });
  const container = document.getElementById('jjfoods');
  if (!!module.hot) {
    const root = createRoot(container);
    root.render(<Root store={store} />);
  } else {
    hydrateRoot(container, <Root store={store} />, {
      onUncaughtError: error => {
        // TODO console.log('Hydrate onUncaughtError', error);
      },
      onCaughtError: error => {
        // TODO console.log('Hydrate onCaughtError', error)
      },
      onRecoverableError: error => {
        // TODO console.log('Hydrate onRecoverableError', error),
      },
    });
  }

  registerServiceWorker();
};
if (!window.__PRELOADED_STATE__ && !module.hot) {
  document.addEventListener('DOMContentLoaded', () => {
    let observer;
    let gotData = false;
    observer = new MutationObserver((mutations, obs) => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList' && !gotData) {
          const configElement = document.getElementById('jjs-script');
          if (configElement && configElement.textContent) {
            gotData = true;
            let preloadedState;
            try {
              preloadedState = JSON.parse(configElement.textContent);
            } catch (e) {
              Sentry && Sentry.captureException && Sentry.captureException(e);
            }
            obs.disconnect();
            init(preloadedState);
            return;
          }
        }
      }
    });
    observer.observe(document.body, {childList: true, subtree: true});
  });
} else {
  const preloadedState = window.__PRELOADED_STATE__ || {};
  window.__PRELOADED_STATE__ = null;
  init(preloadedState);
}
