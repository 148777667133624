export const GET_INIT_DATA_SUCCESS = 'INIT.GET_INIT_DATA_SUCCESS';
export const GET_INIT_DATA_FAILED = 'INIT.GET_INIT_DATA_FAILED';
export const GET_INIT_DATA_REQUESTED = 'INIT.GET_INIT_DATA_REQUESTED';

export const GET_CMS_BANNERS_REQUESTED = 'CMS.GET_BANNERS_REQUESTED';
export const GET_CMS_BANNERS_SUCCESS = 'CMS.GET_BANNERS_SUCCESS';
export const GET_CMS_BANNERS_FAILED = 'CMS.GET_BANNERS_FAILED';

export const GET_CMS_ABOUTUS_REQUESTED = 'CMS.GET_ABOUTUS_REQUESTED';
export const GET_CMS_ABOUTUS_SUCCESS = 'CMS.GET_ABOUTUS_SUCCESS';
export const GET_CMS_ABOUTUS_FAILED = 'CMS.GET_ABOUTUS_FAILED';

export const GET_CMS_CAREERS_REQUESTED = 'CMS.GET_CAREERS_REQUESTED';
export const GET_CMS_CAREERS_SUCCESS = 'CMS.GET_CAREERS_SUCCESS';
export const GET_CMS_CAREERS_FAILED = 'CMS.GET_CAREERS_FAILED';

export const GET_CMS_CUSTOMERS_REQUESTED = 'CMS.GET_CUSTOMERS_REQUESTED';
export const GET_CMS_CUSTOMERS_SUCCESS = 'CMS.GET_CUSTOMERS_SUCCESS';
export const GET_CMS_CUSTOMERS_FAILED = 'CMS.GET_CUSTOMERS_FAILED';

export const GET_CMS_BROCHURES_REQUESTED = 'CMS.GET_BROCHURES_REQUESTED';
export const GET_CMS_BROCHURES_SUCCESS = 'CMS.GET_BROCHURES_SUCCESS';
export const GET_CMS_BROCHURES_FAILED = 'CMS.GET_BROCHURES_FAILED';

export const GET_CMS_OWN_LABELS_REQUESTED = 'CMS.GET_CMS_OWN_LABELS_REQUESTED';
export const GET_CMS_OWN_LABELS_SUCCESS = 'CMS.GET_CMS_OWN_LABELS_SUCCESS';
export const GET_CMS_OWN_LABELS_FAILED = 'CMS.GET_CMS_OWN_LABELS_FAILED';

export const GET_CMS_VACANCIES_REQUESTED = 'CMS.GET_VACANCIES_REQUESTED';
export const GET_CMS_VACANCIES_SUCCESS = 'CMS.GET_VACANCIES_SUCCESS';
export const GET_CMS_VACANCIES_FAILED = 'CMS.GET_VACANCIES_FAILED';

export const GET_CMS_DEPARTMENTS_REQUESTED = 'CMS.GET_DEPARTMENTS_REQUESTED';
export const GET_CMS_DEPARTMENTS_SUCCESS = 'CMS.GET_DEPARTMENTS_SUCCESS';
export const GET_CMS_DEPARTMENTS_FAILED = 'CMS.GET_DEPARTMENTS_FAILED';

export const GET_CMS_TEAM_REQUESTED = 'CMS.GET_TEAM_REQUESTED';
export const GET_CMS_TEAM_SUCCESS = 'CMS.GET_TEAM_SUCCESS';
export const GET_CMS_TEAM_FAILED = 'CMS.GET_TEAM_FAILED';

export const GET_CMS_HELP_REQUESTED = 'CMS.GET_HELP_REQUESTED';
export const GET_CMS_HELP_SUCCESS = 'CMS.GET_HELP_SUCCESS';
export const GET_CMS_HELP_FAILED = 'CMS.GET_HELP_FAILED';

export const GET_CMS_PBB_FAQ_REQUESTED = 'CMS.GET_CMS_PBB_FAQ_REQUESTED';
export const GET_CMS_PBB_FAQ_SUCCESS = 'CMS.GET_PBB_FAQ_SUCCESS';
export const GET_CMS_PBB_FAQ_FAILED = 'CMS.GET_PBB_FAQ_FAILED';

export const GET_SYSTEM_MESSAGES_SUCCESS = 'SYSTEM.GET_SYSTEM_MESSAGES_SUCCESS';

export const GET_CMS_WHYJJ_REQUESTED = 'CMS.GET_WHYJJ_REQUESTED';
export const GET_CMS_WHYJJ_SUCCESS = 'CMS.GET_WHYJJ_SUCCESS';
export const GET_CMS_WHYJJ_FAILED = 'CMS.GET_WHYJJ_FAILED';

export const GET_CMS_SUPPLYUS_REQUESTED = 'CMS.GET_SUPPLYUS_REQUESTED';
export const GET_CMS_SUPPLYUS_SUCCESS = 'CMS.GET_SUPPLYUS_SUCCESS';
export const GET_CMS_SUPPLYUS_FAILED = 'CMS.GET_SUPPLYUS_FAILED';

export const GET_CMS_POLICIES_REQUESTED = 'CMS.GET_POLICIES_REQUESTED';
export const GET_CMS_POLICIES_SUCCESS = 'CMS.GET_POLICIES_SUCCESS';
export const GET_CMS_POLICIES_FAILED = 'CMS.GET_POLICIES_FAILED';

export const GET_CMS_DELIVERIES_REQUESTED = 'CMS.GET_DELIVERIES_REQUESTED';
export const GET_CMS_DELIVERIES_SUCCESS = 'CMS.GET_DELIVERIES_SUCCESS';
export const GET_CMS_DELIVERIES_FAILED = 'CMS.GET_DELIVERIES_FAILED';

export const SET_CLEAN_MODE_SUCCESS = 'MODE.SET_CLEAN_MODE_SUCCESS';

export const SET_ANNOUNCEMENT_MESSAGES_SUCCESS =
  'SYSTEM.SET_ANNOUNCEMENT_MESSAGES_SUCCESS';
export const SET_ANNOUNCEMENT_MESSAGES_FAILED =
  'SYSTEM.SET_ANNOUNCEMENT_MESSAGES_FAILED';
export const SET_MAINTENANCE_MESSAGES_SUCCESS =
  'SYSTEM.SET_MAINTENANCE_MESSAGES_SUCCESS';
export const SET_MAINTENANCE_MESSAGES_FAILED =
  'SYSTEM.SET_MAINTENANCE_MESSAGES_FAILED';

export const SET_BRANCH_SUCCESS = 'BRANCH.SET_BRANCH_SUCCESS';
export const SET_BRANCH_FAILED = 'BRANCH.SET_BRANCH_FAILED';

export const GET_BASKET_SUCCEEDED = 'BASKET.GET_BASKET_SUCCEEDED';

export const UPDATE_BASKET_TOTAL_SUCCEEDED =
  'BASKET.UPDATE_BASKET_TOTAL_SUCCEEDED';
export const GET_BASKET_FAILED = 'BASKET.GET_BASKET_FAILED';
export const GET_BASKET_REQUESTED = 'BASKET.GET_BASKET_REQUESTED';
export const GET_BASKET_SILENTLY_REQUESTED =
  'BASKET.GET_BASKET_SILENTLY_REQUESTED';
export const GET_BASKET_SERVER_ERROR = 'BASKET.GET_BASKET_SERVER_ERROR';
export const INVALID_BASKET_UUID_ERROR = 'BASKET.INVALID_BASKET_UUID_ERROR';
export const AX_BASKET_ALREADY_PICKED = 'BASKET.AX_BASKET_ALREADY_PICKED';
export const AX_BASKET_NOT_EDITABLE = 'BASKET.AX_BASKET_NOT_EDITABLE';
export const AX_BASKET_NOT_FOUND = 'BASKET.AX_BASKET_NOT_FOUND';
export const SET_BASKET_HASHMAP_SUCCEEDED =
  'BASKET.SET_BASKET_HASHMAP_SUCCEEDED';
export const CHECK_BASKET_SYNC_SUCCEEDED = 'BASKET.CHECK_BASKET_SYNC_SUCCEEDED';
export const CHECK_BASKET_SYNC_REQUESTED = 'BASKET.CHECK_BASKET_SYNC_REQUESTED';
export const CHECK_BASKET_SYNC_FAILED = 'BASKET.CHECK_BASKET_SYNC_FAILED';
export const BASKET_NOT_FOUND = 'BASKET.BASKET_NOT_FOUND';

export const UPDATE_BASKET_SUCCEEDED = 'BASKET.UPDATE_BASKET_SUCCEEDED';
export const UPDATE_BASKET_FAILED = 'BASKET.UPDATE_BASKET_FAILED';
export const UPDATE_BASKET_REQUESTED = 'BASKET.UPDATE_BASKET_REQUESTED';

export const ADD_ALL_SHOPPING_LIST_ITEMS_REQUESTED =
  'BASKET.ADD_ALL_SHOPPING_LIST_ITEMS_REQUESTED';
export const ADD_ALL_SHOPPING_LIST_ITEMS_SUCCESS =
  'BASKET.ADD_ALL_SHOPPING_LIST_ITEMS_SUCCESS';
export const ADD_ALL_SHOPPING_LIST_ITEMS_FAILED =
  'BASKET.ADD_ALL_SHOPPING_LIST_ITEMS_FAILED';

export const REORDER_BASKET_REQUESTED = 'BASKET.REORDER_BASKET_REQUESTED';
export const REORDER_BASKET_SUCCEEDED = 'BASKET.REORDER_BASKET_SUCCEEDED';
export const REORDER_BASKET_FAILED = 'BASKET.REORDER_BASKET_FAILED';

export const EDIT_ORDER_REQUESTED = 'BASKET.EDIT_ORDER_REQUESTED';
export const EDIT_ORDER_SUCCEEDED = 'BASKET.EDIT_ORDER_SUCCEEDED';
export const EDIT_ORDER_FAILED = 'BASKET.EDIT_ORDER_FAILED';

export const ADD_VOUCHER_REQUESTED = 'BASKET.ADD_VOUCHER_REQUESTED';
export const ADD_VOUCHER_SUCCEEDED = 'BASKET.ADD_VOUCHER_SUCCEEDED';
export const ADD_VOUCHER_FAILED = 'BASKET.ADD_VOUCHER_FAILED';
export const REMOVE_VOUCHER_REQUESTED = 'BASKET.REMOVE_VOUCHER_REQUESTED';
export const REMOVE_VOUCHER_SUCCEEDED = 'BASKET.REMOVE_VOUCHER_SUCCEEDED';
export const REMOVE_VOUCHER_FAILED = 'BASKET.REMOVE_VOUCHER_FAILED';
export const REMOVE_AX_BASKET_REQUESTED = 'BASKET.REMOVE_AX_BASKET_REQUESTED';
export const REMOVE_AX_BASKET_SUCCEEDED = 'BASKET.REMOVE_AX_BASKET_SUCCEEDED';
export const REMOVE_AX_BASKET_FAILED = 'BASKET.REMOVE_AX_BASKET_FAILED';
export const CLEAR_VOUCHER_ERRORS_SUCCEEDED =
  'BASKET.CLEAR_VOUCHER_ERRORS_SUCCEEDED';

export const UPDATE_BASKET_ITEM_SUCCEEDED =
  'BASKET.UPDATE_BASKET_ITEM_SUCCEEDED';
export const UPDATE_BASKET_ITEM_FAILED = 'BASKET.UPDATE_BASKET_ITEM_FAILED';
export const UPDATE_BASKET_ITEM_REQUESTED =
  'BASKET.UPDATE_BASKET_ITEM_REQUESTED';
export const UPDATE_BASKET_ITEM_CANCELLED =
  'BASKET.UPDATE_BASKET_ITEM_CANCELLED';

export const REMOVE_BASKET_ITEM_SUCCEEDED =
  'BASKET.REMOVE_BASKET_ITEM_SUCCEEDED';

export const REMOVE_BASKET_SUCCEEDED = 'BASKET.REMOVE_BASKET_SUCCEEDED';

export const ADD_TO_BASKET_FINISHED = 'LOADING.ADD_TO_BASKET_FINISHED';

export const REMOVE_BASKET_ITEM_FAILED = 'BASKET.REMOVE_BASKET_ITEM_FAILED';
export const REMOVE_BASKET_ITEM_REQUESTED =
  'BASKET.REMOVE_BASKET_ITEM_REQUESTED';
export const SYNC_BASKET_ITEMS_WITH_MESSAGE_SUCCESS =
  'BASKETITEMS.SYNC_BASKET_ITEMS_WITH_MESSAGE_SUCCESS';
export const RESET_BASKET_ITEMS_SUCCEEDED =
  'BASKETITEMS.RESET_BASKET_ITEMS_SUCCEEDED';
export const SYNC_BASKET_ITEMS_SUCCESS =
  'BASKETITEMS.SYNC_BASKET_ITEMS_SUCCESS';

export const SET_SUBSTITUTE_ITEM_REQUESTED =
  'BASKET.SET_SUBSTITUTE_ITEM_REQUESTED';
export const SET_SUBSTITUTE_ITEM_SUCCEEDED =
  'BASKET.SET_SUBSTITUTE_ITEM_SUCCEEDED';
export const SET_SUBSTITUTE_ITEM_IN_BASKET_SUCCEEDED =
  'BASKET.SET_SUBSTITUTE_ITEM_IN_BASKET_SUCCEEDED';
export const SET_SUBSTITUTE_ITEM_FAILED = 'BASKET.SET_SUBSTITUTE_ITEM_FAILED';

export const GET_ORDER_REQUESTED = 'ORDER.GET_ORDER_REQUESTED';
export const GET_ORDER_SUCCEEDED = 'ORDER.GET_ORDER_SUCCEEDED';
export const GET_ORDER_FAILED = 'ORDER.GET_ORDER_FAILED';
export const RESET_ORDERS_SUCCESS = 'ORDER.RESET_ORDERS_SUCCESS';
export const GET_ORDER_AFTER_CHECKOUT_REQUESTED =
  'ORDER.GET_ORDER_AFTER_CHECKOUT_REQUESTED';
export const GET_ORDER_AFTER_CHECKOUT_FAILED =
  'ORDER.GET_ORDER_AFTER_CHECKOUT_FAILED';
export const SET_ORDER_AFTER_CHECKOUT = 'ORDER.SET_ORDER_AFTER_CHECKOUT';

export const GET_INVOICED_ORDER_REQUESTED =
  'ORDER.GET_INVOICED_ORDER_REQUESTED';
export const GET_INVOICED_ORDER_FAILED = 'ORDER.GET_INVOICED_ORDER_FAILED';

export const CANCEL_ORDER_REQUESTED = 'ORDER.CANCEL_ORDER_REQUESTED';
export const CANCEL_ORDER_SUCCEEDED = 'ORDER.CANCEL_ORDER_SUCCEEDED';
export const CANCEL_ORDER_FAILED = 'ORDER.CANCEL_ORDER_FAILED';

export const REAUTH_ORDER_REQUESTED = 'ORDER.REAUTH_ORDER_REQUESTED';
export const REAUTH_ORDER_SUCCEEDED = 'ORDER.REAUTH_ORDER_SUCCEEDED';
export const REAUTH_ORDER_FAILED = 'ORDER.REAUTH_ORDER_FAILED';

export const GET_ORDER_HISTORY_REQUESTED = 'ORDER.GET_ORDER_HISTORY_REQUESTED';
export const GET_ORDER_HISTORY_SUCCESS = 'ORDER.GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILED = 'ORDER.GET_ORDER_HISTORY_FAILED';

export const GET_INVOICES_REQUESTED = 'ORDER.GET_INVOICES_REQUESTED';
export const GET_INVOICES_SUCCESS = 'ORDER.GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILED = 'ORDER.GET_INVOICES_FAILED';

export const GET_MORE_ORDER_HISTORY_REQUESTED =
  'ORDER.GET_MORE_ORDER_HISTORY_REQUESTED';
export const GET_MORE_ORDER_HISTORY_SUCCESS =
  'ORDER.GET_MORE_ORDER_HISTORY_SUCCESS';

export const GET_MORE_INVOICES_SUCCESS = 'ORDER.GET_MORE_INVOICES_SUCCESS';

export const GET_ACTIVE_ORDERS_SUCCESS = 'ORDER.GET_ACTIVE_ORDERS_SUCCESS';
export const GET_ACTIVE_ORDERS_FAILED = 'ORDER.GET_ACTIVE_ORDERS_FAILED';

export const GET_SEARCH_RESULTS_SUCCESS = 'SEARCH.GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILED = 'SEARCH.GET_SEARCH_RESULTS_FAILED';
export const GET_SEARCH_RESULTS_REQUESTED =
  'SEARCH.GET_SEARCH_RESULTS_REQUESTED';

export const GET_MORE_SEARCH_RESULTS_SUCCESS =
  'SEARCH.GET_MORE_SEARCH_RESULTS_SUCCESS';
export const GET_MORE_SEARCH_RESULTS_FAILED =
  'SEARCH.GET_MORE_SEARCH_RESULTS_FAILED';
export const GET_MORE_SEARCH_RESULTS_REQUESTED =
  'SEARCH.GET_MORE_SEARCH_RESULTS_REQUESTED';

export const GET_SEARCH_SUGGESTIONS_REQUESTED =
  'SEARCH.GET_SEARCH_SUGGESTIONS_REQUESTED';
export const GET_SEARCH_SUGGESTIONS_SUCCESS =
  'SEARCH.GET_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_SEARCH_SUGGESTIONS_FAILED =
  'SEARCH.GET_SEARCH_SUGGESTIONS_FAILED';
export const GET_SEARCH_SUGGESTIONS_RESET =
  'SEARCH.GET_SEARCH_SUGGESTIONS_RESET';
export const GET_SEARCH_CATEGORY_DESCRIPTION_REQUESTED =
  'SEARCH.GET_SEARCH_CATEGORY_DESCRIPTION_REQUESTED';
export const GET_SEARCH_CATEGORY_DESCRIPTION_SUCCESS =
  'SEARCH.GET_SEARCH_CATEGORY_DESCRIPTION_SUCCESS';
export const GET_SEARCH_CATEGORY_DESCRIPTION_FAILED =
  'SEARCH.GET_SEARCH_CATEGORY_DESCRIPTION_FAILED';

export const GET_MORE_SEARCH_RESULTS_PAGE_SUCCESS =
  'SEARCH.GET_MORE_SEARCH_RESULTS_PAGE_SUCCESS';
export const GET_SEARCH_RESULTS_PAGE_SUCCESS =
  'SEARCH.GET_SEARCH_RESULTS_PAGE_SUCCESS';

export const GET_OFFERS_RESULTS_PAGE_SUCCESS =
  'OFFERS.GET_OFFERS_RESULTS_PAGE_SUCCESS';

export const GET_SEARCH_AGGREGATE_SUCCESS =
  'SEARCH.GET_SEARCH_AGGREGATE_SUCCESS';
export const GET_SEARCH_AGGREGATE_FAILED = 'SEARCH.GET_SEARCH_AGGREGATE_FAILED';
export const GET_SEARCH_AGGREGATE_REQUESTED =
  'SEARCH.GET_SEARCH_AGGREGATE_REQUESTED';

export const GET_MORE_SEARCH_AGGREGATE_SUCCESS =
  'SEARCH.GET_MORE_SEARCH_AGGREGATE_SUCCESS';

export const GET_NEAREST_BRANCH_REQUESTED = `BRANCH.GET_NEAREST_BRANCH_REQUESTED`;
export const GET_NEAREST_BRANCH_SUCCESS = `BRANCH.GET_NEAREST_BRANCH_SUCCESS`;
export const GET_NEAREST_BRANCH_FAILED = `BRANCH.GET_NEAREST_BRANCH_FAILED`;
export const CLEAR_INVALID_POSTCODE_SUCCESS = `BRANCH.CLEAR_INVALID_POSTCODE_SUCCESS`;

export const GET_BRANCH_OPENING_TIMES_REQUESTED =
  'BRANCH.GET_BRANCH_OPENING_TIMES_REQUESTED';
export const GET_BRANCH_OPENING_TIMES_SUCCESS =
  'BRANCH.GET_BRANCH_OPENING_TIMES_SUCCESS';
export const GET_BRANCH_OPENING_TIMES_FAILED =
  'BRANCH.GET_BRANCH_OPENING_TIMES_FAILED';

export const GET_PRODUCTS_BY_IDS_SUCCESS =
  'PRODUCT.GET_PRODUCTS_BY_IDS_SUCCESS';
export const GET_PRODUCTS_BY_IDS_FAILED = 'PRODUCT.GET_PRODUCTS_BY_IDS_FAILED';
export const GET_PRODUCTS_BY_IDS_REQUESTED =
  'PRODUCT.GET_PRODUCTS_BY_IDS_REQUESTED';

export const GET_PRODUCT_DETAIL_SUCCESS = 'PRODUCT.GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILED = 'PRODUCT.GET_PRODUCT_DETAIL_FAILED';
export const GET_PRODUCT_DETAIL_REQUESTED =
  'PRODUCT.GET_PRODUCT_DETAIL_REQUESTED';
export const RESET_PRODUCT_DETAIL_SUCCESS =
  'PRODUCT.RESET_PRODUCT_DETAIL_SUCCESS';

export const GET_OFFERS_HOMEPAGE_SUCCESS = 'HOME.GET_OFFERS_HOMEPAGE_SUCCESS';
export const GET_OFFERS_HOMEPAGE_FAILED = 'HOME.GET_OFFERS_HOMEPAGE_FAILED';
export const GET_OFFERS_HOMEPAGE_REQUESTED =
  'HOME.GET_OFFERS_HOMEPAGE_REQUESTED';

export const GET_OFFERS_TODAY_REQUESTED = 'OFFERS.GET_OFFERS_TODAY_REQUESTED';
export const GET_OFFERS_TODAY_SUCCESS = 'OFFERS.GET_OFFERS_TODAY_SUCCESS';
export const GET_OFFERS_TODAY_FAILED = 'OFFERS.GET_OFFERS_TODAY_FAILED';

export const GET_OFFERS_AGGREGATE_REQUESTED =
  'OFFERS.GET_OFFERS_AGGREGATE_REQUESTED';
export const GET_OFFERS_AGGREGATE_SUCCESS =
  'OFFERS.GET_OFFERS_AGGREGATE_SUCCESS';
export const GET_OFFERS_AGGREGATE_FAILED = 'OFFERS.GET_OFFERS_AGGREGATE_FAILED';

export const GET_MORE_OFFERS_REQUESTED = 'OFFERS.GET_MORE_OFFERS_REQUESTED';
export const GET_MORE_OFFERS_SUCCESS = 'OFFERS.GET_MORE_OFFERS_SUCCESS';
export const GET_MORE_OFFERS_FAILED = 'OFFERS.GET_MORE_OFFERS_FAILED';

export const GET_BRANCH_LIST_SUCCESS = 'BRANCH_LIST.GET_BRANCH_LIST_SUCCESS';
export const GET_BRANCH_LIST_FAILED = 'BRANCH_LIST.GET_BRANCH_LIST_FAILED';
export const GET_BRANCH_LIST_REQUESTED =
  'BRANCH_LIST.GET_BRANCH_LIST_REQUESTED';

export const SET_CURRENT_ROUTE_SUCCESS = 'ROUTE.SET_CURRENT_ROUTE_SUCCESS';
export const SET_PREV_ROUTE_SUCCESS = 'ROUTE.SET_PREV_ROUTE_SUCCESS';

export const GET_POSTCODE_LOOKUP_REQUESTED =
  'POSTCODE.GET_POSTCODE_LOOKUP_REQUESTED';
export const GET_POSTCODE_LOOKUP_SUCCESS =
  'POSTCODE.GET_POSTCODE_LOOKUP_SUCCESS';
export const GET_POSTCODE_LOOKUP_FAILED = 'POSTCODE.GET_POSTCODE_LOOKUP_FAILED';
export const RESET_POSTCODE_LOOKUP_REQUESTED =
  'POSTCODE.RESET_POSTCODE_LOOKUP_REQUESTED';

export const GET_ADDRESS_LOOKUP_REQUESTED =
  'ADDRESS.GET_ADDRESS_LOOKUP_REQUESTED';
export const GET_ADDRESS_LOOKUP_SUCCESS = 'ADDRESS.GET_ADDRESS_LOOKUP_SUCCESS';
export const GET_ADDRESS_LOOKUP_FAILED = 'ADDRESS.GET_ADDRESS_LOOKUP_FAILED';
export const RESET_ADDRESS_LOOKUP_REQUESTED =
  'ADDRESS.RESET_ADDRESS_LOOKUP_REQUESTED';

// Errors
export const ERROR_CLEAN_UP = 'ERROR.CLEAN_UP'; //reset error code
export const RESET_ERRORS = 'ERRORS.RESET_ERRORS';
export const ERROR_LOGIN_EXPIRED = 'ERROR.LOGIN_EXPIRED';
export const ERROR_UNAUTHORIZED = 'ERROR.UNAUTHORIZED';
export const ERROR_REQUIRE_LOGIN = 'ERROR.REQUIRE_LOGIN';
export const SERVER_ERROR = 'ERROR.HTTP_SERVER_ERROR';
export const BASKET_ERROR = 'ERROR.BASKET_ERROR';
export const SET_ERROR = 'ERROR.SET_ERROR';
export const BASKET_NO_ITEMS_ERROR = 'ERROR.BASKET_NO_ITEMS_ERROR';
export const BASKET_ONLY_OOS_ITEMS_ERROR = 'ERROR.BASKET_ONLY_OOS_ITEMS_ERROR';
export const BASKET_MIN_DELIVERY_ORDER_ERROR =
  'ERROR.BASKET_MIN_DELIVERY_ORDER_ERROR';
export const ORDER_ERROR = 'ERROR.ORDER_ERROR';
export const ENHANCE_TOKEN_FAILED = 'ERROR.ENHANCE_TOKEN_FAILED';
export const SESSION_STORAGE_UNSUPPORTED = 'ERROR.SESSION_STORAGE_UNSUPPORTED';

// Checkout
export const PROCESS_CHECKOUT_REQUESTED = 'CHECKOUT.PROCESS_CHECKOUT_REQUESTED';
export const PROCESS_CHECKOUT_SUCCESS = 'CHECKOUT.PROCESS_CHECKOUT_SUCCESS';
export const PROCESS_CHECKOUT_FAILED = 'CHECKOUT.PROCESS_CHECKOUT_FAILED';
export const PROCESS_CHECKOUT_VALIDATION_FAILED =
  'CHECKOUT.PROCESS_CHECKOUT_VALIDATION_FAILED';
export const AUTHORIZE_NON_CARD_PAYMENT_REQUESTED =
  'CHECKOUT.AUTHORIZE_NON_CARD_PAYMENT_REQUESTED';
export const AUTHORIZE_NON_CARD_PAYMENT_FAILED =
  'CHECKOUT.AUTHORIZE_NON_CARD_PAYMENT_FAILED';
export const AUTHORIZE_BRAINTREE_PAYMENT_REQUESTED =
  'CHECKOUT.AUTHORIZE_BRAINTREE_PAYMENT_REQUESTED';
export const AUTHORIZE_BRAINTREE_PAYMENT_FAILED =
  'CHECKOUT.AUTHORIZE_BRAINTREE_PAYMENT_FAILED';
export const AUTHORIZE_BRAINTREE_PAYMENT_SUCCESS =
  'CHECKOUT.AUTHORIZE_BRAINTREE_PAYMENT_SUCCESS';

export const AUTHORIZE_ADYEN_PAYMENT_REQUESTED =
  'CHECKOUT.AUTHORIZE_ADYEN_PAYMENT_REQUESTED';
export const AUTHORIZE_ADYEN_PAYMENT_FAILED =
  'CHECKOUT.AUTHORIZE_ADYEN_PAYMENT_FAILED';
export const AUTHORIZE_ADYEN_PAYMENT_SUCCESS =
  'CHECKOUT.AUTHORIZE_ADYEN_PAYMENT_SUCCESS';

export const AUTHORIZE_ADYEN_THREEDS_FAILED =
  'CHECKOUT.AUTHORIZE_ADYEN_THREEDS_FAILED';
export const AUTHORIZE_ADYEN_THREEDS_SUCCESS =
  'CHECKOUT.AUTHORIZE_ADYEN_THREEDS_SUCCESS';
export const CHECKOUT_SLOT_EXPIRED = 'CHECKOUT.CHECKOUT_SLOT_EXPIRED';
export const CHECKOUT_TOTAL_DISCREPANCY = 'CHECKOUT.TOTAL_DISCREPANCY';

export const RESET_CHECKOUT_REQUESTED = 'CHECKOUT.RESET_CHECKOUT_REQUESTED';

// Fulfillment
export const SET_FULFILLMENT_REQUESTED = 'BASKET.SET_FULFILLMENT_REQUESTED';
export const SET_FULFILLMENT_SUCCESS = 'BASKET.SET_FULFILLMENT_SUCCESS';
export const SET_FULFILLMENT_FAILED = 'BASKET.FULFILLMENT_FAILED';
export const SET_FULFILLMENT_DETAILS_SUCCESS =
  'BASKET.SET_FULFILLMENT_DETAILS_SUCCESS';
export const SET_PAYMENT_TYPE_REQUESTED = 'BASKET.SET_PAYMENT_TYPE_REQUESTED';
export const SET_PAYMENT_TYPE_SUCCESS = 'BASKET.SET_PAYMENT_TYPE_SUCCESS';
export const SET_PAYMENT_TYPE_FAILED = 'BASKET.SET_PAYMENT_TYPE_FAILED';
// Profile
export const SET_PROFILE_SUCCESS = 'PROFILE.SET_PROFILE_SUCCESS';

// AUTH
export const SET_AUTH_SUCCESS = 'AUTH.SET_AUTH_SUCCESS';
export const SET_AUTH_FROM_CACHE_SUCCESS = 'AUTH.SET_AUTH_FROM_CACHE_SUCCESS';
export const REMOVE_AUTH_SUCCESS = 'AUTH.REMOVE_AUTH_SUCCESS';

export const REMOVE_LOGIN_AUTH_REQUESTED = 'AUTH.REMOVE_LOGIN_AUTH_REQUESTED';
export const REMOVE_AUTH_REQUESTED = 'AUTH.REMOVE_AUTH_REQUESTED';
export const REMOVE_AUTH_FAILED = 'AUTH.REMOVE_AUTH_FAILED';
export const REFRESH_AUTH_REQUESTED = 'AUTH.REFRESH_AUTH_REQUESTED';
export const REFRESH_AUTH_SUCCESS = 'AUTH.REFRESH_AUTH_SUCCESS';
export const REFRESH_AUTH_FAILED = 'AUTH.REFRESH_AUTH_FAILED';
export const GET_AUTH_REQUESTED = 'AUTH.GET_AUTH_REQUESTED';
export const GET_AUTH_FAILED = 'AUTH.GET_AUTH_FAILED';
export const REMOVE_LOGIN_TOKEN_REQUESTED = 'AUTH.REMOVE_LOGIN_TOKEN_REQUESTED';
export const GET_TOKEN_BY_PASSWORD_REQUESTED =
  'AUTH.GET_TOKEN_BY_PASSWORD_REQUESTED';
export const GET_TOKEN_BY_PASSWORD_SUCCESS =
  'AUTH.GET_TOKEN_BY_PASSWORD_SUCCESS';
export const GET_TOKEN_BY_PASSWORD_FAILED = 'AUTH.GET_TOKEN_BY_PASSWORD_FAILED';
export const FORCE_LOGIN_REQUESTED = 'AUTH.FORCE_LOGIN_REQUESTED';

export const GET_LOGIN_TOKEN_REQUESTED = 'AUTH.GET_LOGIN_TOKEN_REQUESTED';
export const SET_LOGIN_TOKEN_FAILED = 'AUTH.SET_LOGIN_TOKEN_FAILED';
export const SET_LOGIN_TOKEN_SUCCESS = 'AUTH.SET_LOGIN_TOKEN_SUCCESS'; // double check
export const SET_REFRESH_TOKEN_SUCCESS = 'AUTH.SET_REFRESH_TOKEN_SUCCESS';

// NAVIGATE
export const NAVIGATE_TO_CHECKOUT = 'NAVIGATE.NAVIGATE_TO_CHECKOUT';
export const NAVIGATE_TO_ROUTE = 'NAVIGATE.NAVIGATE_TO_ROUTE';
export const NAVIGATE_RESET = 'NAVIGATE.RESET';
export const SET_NEXT_ROUTE_SUCCESS = 'NAVIGATE.SET_NEXT_ROUTE_SUCCESS';

// RECOMMENDED
export const GET_RECOMMENDED_ITEMS_REQUESTED =
  'BASKET.GET_RECOMMENDED_ITEMS_REQUESTED';
export const GET_RECOMMENDED_ITEMS_SUCCESS =
  'BASKET.GET_RECOMMENDED_ITEMS_SUCCESS';
export const GET_RECOMMENDED_ITEMS_FAILED =
  'BASKET.GET_RECOMMENDED_ITEMS_FAILED';
export const GET_RECOMMENDED_ITEMS_RESET = 'BASKET.GET_RECOMMENDED_ITEMS_RESET';

export const GET_UPSELLING_ITEMS_REQUESTED =
  'UPSELLING.GET_UPSELLING_ITEMS_REQUESTED';
export const GET_UPSELLING_ITEMS_FAILED =
  'UPSELLING.GET_UPSELLING_ITEMS_FAILED';
export const GET_UPSELLING_ITEMS_SUCCESS =
  'UPSELLING.GET_UPSELLING_ITEMS_SUCCESS';
export const GET_FORGOTTEN_ITEMS_SUCCESS =
  'UPSELLING.GET_FORGOTTEN_ITEMS_SUCCESS';
export const GET_FORGOTTEN_ITEMS_FAILED =
  'UPSELLING.GET_FORGOTTEN_ITEMS_FAILED';
export const GET_SEE_THOSE_ITEMS_SUCCESS =
  'UPSELLING.GET_SEE_THOSE_ITEMS_SUCCESS';
export const GET_SEE_THOSE_ITEMS_FAILED =
  'UPSELLING.GET_SEE_THOSE_ITEMS_FAILED';
export const GET_FLASH_SALES_ITEMS_SUCCESS =
  'UPSELLING.GET_FLASH_SALES_ITEMS_SUCCESS';
export const GET_FLASH_SALES_ITEMS_FAILED =
  'UPSELLING.GET_FLASH_SALES_ITEMS_FAILED';

// FULFILL SLOT
export const GET_FULFILL_SLOT_SUCCESS = 'SLOT.GET_FULFILL_SLOT_SUCCESS';
export const GET_FULFILL_SLOT_FAILED = 'SLOT.GET_FULFILL_SLOT_FAILED';
export const GET_FULFILL_SLOT_REQUESTED = 'SLOT.GET_FULFILL_SLOT_REQUESTED';
export const GET_EMPTY_FULFILL_SLOT = 'SLOT.GET_EMPTY_FULFILL_SLOT';
export const RESET_FULFILL_SLOTS_SUCCESS = 'SLOT.RESET_FULFILL_SLOTS_SUCCESS';

export const SET_FULFILL_SLOT_SUCCESS = 'SLOT.SET_FULFILL_SLOT_SUCCESS';
export const SET_FULFILL_SLOT_FAILED = 'SLOT.SET_FULFILL_SLOT_FAILED';
export const SET_FULFILL_SLOT_REQUESTED = 'SLOT.SET_FULFILL_SLOT_REQUESTED';

export const DELETE_FULFILL_SLOT_SUCCESS = 'SLOT.DELETE_FULFILL_SLOT_SUCCESS';
export const DELETE_FULFILL_SLOT_FAILED = 'SLOT.DELETE_FULFILL_SLOT_FAILED';
export const DELETE_FULFILL_SLOT_REQUESTED =
  'SLOT.DELETE_FULFILL_SLOT_REQUESTED';

// ACCOUNTS
export const GET_ACCOUNTS_SUCCESS = 'ACCOUNTS.GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FROM_CACHE_SUCCESS =
  'ACCOUNTS.GET_ACCOUNTS_FROM_CACHE_SUCCESS';
export const GET_ACCOUNTS_FAILED = 'ACCOUNTS.GET_ACCOUNTS_FAILED';
export const GET_ACCOUNTS_REQUESTED = 'ACCOUNTS.GET_ACCOUNTS_REQUESTED';
export const NO_ACTIVE_ACCOUNTS = 'ACCOUNTS.NO_ACTIVE_ACCOUNTS';

export const SET_ACCOUNTS_REQUESTED = 'ACCOUNT.SET_ACCOUNTS_REQUESTED';
export const SET_ACCOUNTS_SUCCESS = 'ACCOUNT.SET_ACCOUNTS_SUCCESS';
export const SET_ACCOUNTS_FAILED = 'ACCOUNT.SET_ACCOUNTS_FAILED';
export const SET_SELECTED_ACCOUNTS_SUCCESS =
  'ACCOUNT.SET_SELECTED_ACCOUNTS_SUCCESS';

export const GET_PAYMENT_DETAILS_REQUESTED =
  'ACCOUNT.GET_PAYMENT_DETAILS_REQUESTED';
export const GET_PAYMENT_DETAILS_SUCCESS =
  'ACCOUNT.GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILED = 'ACCOUNT.GET_PAYMENT_DETAILS_FAILED';
export const SET_PAYMENT_DETAILS_REQUESTED =
  'ACCOUNT.SET_PAYMENT_DETAILS_REQUESTED';
export const SET_PAYMENT_DETAILS_SUCCESS =
  'ACCOUNT.SET_PAYMENT_DETAILS_SUCCESS';
export const SET_PAYMENT_DETAILS_FAILED = 'ACCOUNT.SET_PAYMENT_DETAILS_FAILED';

export const SEARCH_ACCOUNTS_REQUESTED = 'ACCOUNT.SEARCH_ACCOUNTS_REQUESTED';
export const SEARCH_ACCOUNTS_SUCCESS = 'ACCOUNT.SEARCH_ACCOUNTS_SUCCESS';
export const SEARCH_ACCOUNTS_FAILED = 'ACCOUNT.SEARCH_ACCOUNTS_FAILED';
export const RESET_ACCOUNTS_REQUESTED = 'ACCOUNT.RESET_ACCOUNTS_REQUESTED';

// MODAL
export const TOGGLE_SWITCH_ACCOUNT_MODAL_SUCCESS =
  'MODAL.TOGGLE_SWITCH_ACCOUNT_MODAL_SUCCESS';
export const TOGGLE_UPSELLING_MODAL_SUCCESS =
  'MODAL.TOGGLE_UPSELLING_MODAL_SUCCESS';
export const TOGGLE_SUBSTITUTES_MODAL_SUCCESS =
  'MODAL.TOGGLE_SUBSTITUTES_MODAL_SUCCESS';
export const TOGGLE_STOP_EDIT_MODAL_SUCCESS =
  'MODAL.TOGGLE_STOP_EDIT_MODAL_SUCCESS';
export const TOGGLE_AVAILABLE_ONLY_MODAL_SUCCESS =
  'MODAL.TOGGLE_AVAILABLE_ONLY_MODAL_SUCCESS';
export const TOGGLE_CHANGE_BRANCH_MODAL_SUCCESS =
  'MODAL.TOGGLE_CHANGE_BRANCH_MODAL_SUCCESS';
export const TOGGLE_OPEN_IN_JJS_MODAL_SUCCESS =
  'MODAL.TOGGLE_OPEN_IN_JJS_MODAL_SUCCESS';
export const TOGGLE_MAINTENANCE_MODAL_SUCCESS =
  'MODAL.TOGGLE_MAINTENANCE_MODAL_SUCCESS';
export const TOGGLE_RELOGIN_MODAL_SUCCESS =
  'MODAL.TOGGLE_RELOGIN_MODAL_SUCCESS';
export const TOGGLE_PAYMENT_DETAILS_MODAL_SUCCESS =
  'MODAL.TOGGLE_PAYMENT_DETAILS_MODAL_SUCCESS';
export const TOGGLE_CATCH_WEIGHT_MODAL_SUCCESS =
  'MODAL.TOGGLE_CATCH_WEIGHT_MODAL_SUCCESS';
export const TOGGLE_BASKET_SYNC_FAILED_MODAL_SUCCESS =
  'MODAL.TOGGLE_BASKET_SYNC_FAILED_MODAL_SUCCESS';
export const TOGGLE_CUSTOMER_SUPPORT_MODAL_SUCCESS =
  'MODAL.TOGGLE_CUSTOMER_SUPPORT_MODAL_SUCCESS';
export const TOGGLE_RATING_MODAL_SUCCESS = 'MODAL.TOGGLE_RATING_MODAL_SUCCESS';

// BROCHURES
export const GET_BROCHURES_SUCCESS = 'BROCHURES.GET_BROCHURES_SUCCESS';
export const GET_BROCHURES_FAILED = 'BROCHURES.GET_BROCHURES_FAILED';
export const GET_BROCHURES_REQUESTED = 'BROCHURES.GET_BROCHURES_REQUESTED';

// FAVOURITES
export const ADD_TO_FAVOURITES_REQUESTED =
  'PRODUCT.ADD_TO_FAVOURITES_REQUESTED';
export const ADD_TO_FAVOURITES_SUCCESS = 'PRODUCT.ADD_TO_FAVOURITES_SUCCESS';
export const ADD_TO_FAVOURITES_FAILED = 'PRODUCT.ADD_TO_FAVOURITES_FAILED';

export const REMOVE_FROM_FAVOURITES_REQUESTED =
  'PRODUCT.REMOVE_FROM_FAVOURITES_REQUESTED';
export const REMOVE_FROM_FAVOURITES_SUCCESS =
  'PRODUCT.REMOVE_FROM_FAVOURITES_SUCCESS';
export const REMOVE_FROM_FAVOURITES_FAILED =
  'PRODUCT.REMOVE_FROM_FAVOURITES_FAILED';

// PREVIOUSLY PURCHASED REQUESTED
export const GET_PREVIOUSLY_PURCHASED_REQUESTED =
  'PRODUCT.GET_PREVIOUSLY_PURCHASED_REQUESTED';
export const GET_PREVIOUSLY_PURCHASED_SUCCESS =
  'PRODUCT.GET_PREVIOUSLY_PURCHASED_SUCCESS';
export const GET_PREVIOUSLY_PURCHASED_FAILED =
  'PRODUCT.GET_PREVIOUSLY_PURCHASED_FAILED';

export const GET_FAVOURITES_REQUESTED = 'PRODUCT.GET_FAVOURITES_REQUESTED';
export const GET_FAVOURITES_SUCCESS = 'PRODUCT.GET_FAVOURITES_SUCCESS';
export const GET_FAVOURITES_FAILED = 'PRODUCT.GET_FAVOURITES_FAILED';

export const GET_VACANCIES_REQUESTED = 'PRODUCT.GET_VACANCIES_REQUESTED';
export const GET_VACANCIES_SUCCESS = 'PRODUCT.GET_VACANCIES_SUCCESS';
export const GET_VACANCIES_FAILED = 'PRODUCT.GET_VACANCIES_FAILED';

export const GET_MARKETING_OPTIONS_REQUESTED =
  'MARKETING.GET_MARKETING_OPTIONS_REQUESTED';
export const GET_MARKETING_OPTIONS_SUCCESS =
  'MARKETING.GET_MARKETING_OPTIONS_SUCCESS';
export const GET_MARKETING_OPTIONS_FAILED =
  'MARKETING.GET_MARKETING_OPTIONS_FAILED';
export const UPDATE_MARKETING_OPTIONS_REQUESTED =
  'MARKETING.UPDATE_MARKETING_OPTIONS_REQUESTED';
export const UPDATE_MARKETING_OPTIONS_SUCCESS =
  'MARKETING.UPDATE_MARKETING_OPTIONS_SUCCESS';
export const UPDATE_MARKETING_OPTIONS_FAILED =
  'MARKETING.UPDATE_MARKETING_OPTIONS_FAILED';
export const UPDATE_MARKETING_OPTIONS_CANCELLED =
  'MARKETING.UPDATE_MARKETING_OPTIONS_CANCELLED';
export const UPDATE_ALL_MARKETING_OPTIONS_REQUESTED =
  'MARKETING.UPDATE_ALL_MARKETING_OPTIONS_REQUESTED';
export const UPDATE_ALL_MARKETING_OPTIONS_SUCCESS =
  'MARKETING.UPDATE_ALL_MARKETING_OPTIONS_SUCCESS';
export const UPDATE_ALL_MARKETING_OPTIONS_FAILED =
  'MARKETING.UPDATE_ALL_MARKETING_OPTIONS_FAILED';

export const GET_ALL_SETTINGS_REQUESTED = 'SETTINGS.GET_ALL_SETTINGS_REQUESTED';
export const GET_ALL_SETTINGS_SUCCESS = 'SETTINGS.GET_ALL_SETTINGS_SUCCESS';
export const GET_ALL_SETTINGS_FAILED = 'SETTINGS.GET_ALL_SETTINGS_FAILED';
export const UPDATE_SETTING_REQUESTED = 'SETTINGS.UPDATE_SETTING_REQUESTED';
export const UPDATE_SETTING_SUCCESS = 'SETTINGS.UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_FAILED = 'SETTINGS.UPDATE_SETTING_FAILED';
export const RESET_SETTING_SUCCESS = 'SETTINGS.RESET_SETTING_SUCCESS';
export const REMOVE_SETTING_REQUESTED = 'SETTINGS.REMOVE_SETTING_REQUESTED';
export const REMOVE_SETTING_SUCCESS = 'SETTINGS.REMOVE_SETTING_SUCCESS';
export const REMOVE_SETTING_FAILED = 'SETTINGS.REMOVE_SETTING_FAILED';
export const UPDATE_ALL_SETTINGS_REQUESTED =
  'SETTINGS.UPDATE_ALL_SETTINGS_REQUESTED';
export const UPDATE_ALL_SETTINGS_SUCCESS =
  'SETTINGS.UPDATE_ALL_SETTINGS_SUCCESS';
export const UPDATE_ALL_SETTINGS_FAILED = 'SETTINGS.UPDATE_ALL_SETTINGS_FAILED';

export const GET_SUBSTITUTES_SUCCEED = 'SUBSTITUTES.GET_SUBSTITUTES_SUCCEED';
export const GET_SUBSTITUTES_FAILED = 'SUBSTITUTES.GET_SUBSTITUTES_FAILED';

export const REGISTER_USER_REQUESTED = 'ACCOUNT.REGISTER_USER_REQUESTED';
export const REGISTER_USER_SUCCESS = 'ACCOUNT.REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'ACCOUNT.REGISTER_USER_FAILED';

export const PASSWORD_RESET_REQUESTED = 'ACCOUNT.PASSWORD_RESET_REQUESTED';
export const PASSWORD_RESET_SUCCESS = 'ACCOUNT.PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'ACCOUNT.PASSWORD_RESET_FAILED';

export const SET_NEW_PASSWORD_REQUESTED = 'ACCOUNT.SET_NEW_PASSWORD_REQUESTED';
export const SET_NEW_PASSWORD_SUCCESS = 'ACCOUNT.SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILED = 'ACCOUNT.SET_NEW_PASSWORD_FAILED';

export const CHANGE_PASSWORD_REQUESTED = 'ACCOUNT.CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCESS = 'ACCOUNT.CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'ACCOUNT.CHANGE_PASSWORD_FAILED';

export const ACTIVATE_ACCOUNT_REQUESTED = 'ACCOUNT.ACTIVATE_ACCOUNT_REQUESTED';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACCOUNT.ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAILED = 'ACCOUNT.ACTIVATE_ACCOUNT_FAILED';
export const ACTIVATE_ACCOUNT_RESET = 'ACCOUNT.ACTIVATE_ACCOUNT_RESET';

export const SET_STAFF_ACCOUNT_SUCCESS = 'ACCOUNT.SET_STAFF_ACCOUNT_SUCCESS';

export const REFRESH_AX_ACCOUNT_REQUESTED =
  'ACCOUNT.REFRESH_AX_ACCOUNT_REQUESTED';
export const REFRESH_AX_ACCOUNT_SUCCESS = 'ACCOUNT.REFRESH_AX_ACCOUNT_SUCCESS';
export const REFRESH_AX_ACCOUNT_FAILED = 'ACCOUNT.REFRESH_AX_ACCOUNT_FAILED';

export const CONTACT_CUSTOMER_SERVICE_REQUESTED =
  'ACCOUNT.CONTACT_CUSTOMER_SERVICE_REQUESTED';
export const CONTACT_CUSTOMER_SERVICE_SUCCESS =
  'ACCOUNT.CONTACT_CUSTOMER_SERVICE_SUCCESS';
export const CONTACT_CUSTOMER_SERVICE_FAILED =
  'ACCOUNT.CONTACT_CUSTOMER_SERVICE_FAILED';

export const TRACK_ORDER_REQUESTED = 'ORDER.TRACK_ORDER_REQUESTED';
export const TRACK_ORDER_SUCCEEDED = 'ORDER.TRACK_ORDER_SUCCEEDED';
export const TRACK_ORDER_FAILED = 'ORDER.TRACK_ORDER_FAILED';
export const TRACK_ORDER_RESET = 'ORDER.TRACK_ORDER_RESET';

export const SET_DRIVER_INSTRUCTION_SUCCESS =
  'DRIVER_INSTRUCTION.SET_DRIVER_INSTRUCTION_SUCCESS';

export const RESET_RELOGIN = 'LOGIN.RESET_RELOGIN';
export const SET_RELOGIN = 'LOGIN.SET_RELOGIN';
export const SET_LOGIN_REDIRECT_SUCCESS = 'LOGIN.SET_LOGIN_REDIRECT_SUCCESS';
export const RESET_LOGIN_REDIRECT_SUCCESS =
  'LOGIN.RESET_LOGIN_REDIRECT_SUCCESS';

export const GET_ALL_LISTS_REQUESTED = 'LISTS.GET_ALL_LISTS_REQUESTED';
export const GET_ALL_LISTS_SUCCESS = 'LISTS.GET_ALL_LISTS_SUCCESS';
export const GET_ALL_LISTS_FAILED = 'LISTS.GET_ALL_LISTS_FAILED';

export const ADD_TO_SHOPPING_LIST_REQUESTED =
  'LISTS.ADD_TO_SHOPPING_LIST_REQUESTED';
export const ADD_TO_SHOPPING_LIST_SUCCESS =
  'LISTS.ADD_TO_SHOPPING_LIST_SUCCESS';
export const ADD_TO_SHOPPING_LIST_FAILED = 'LISTS.ADD_TO_SHOPPING_LIST_FAILED';

export const REMOVE_FROM_SHOPPING_LIST_REQUESTED =
  'LISTS.REMOVE_FROM_SHOPPING_LIST_REQUESTED';
export const REMOVE_FROM_SHOPPING_LIST_SUCCESS =
  'LISTS.REMOVE_FROM_SHOPPING_LIST_SUCCESS';
export const REMOVE_FROM_SHOPPING_LIST_FAILED =
  'LISTS.REMOVE_FROM_SHOPPING_LIST_FAILED';

export const GET_LISTS_BY_ID_REQUESTED = 'LISTS.GET_LISTS_BY_ID_REQUESTED';
export const GET_LISTS_BY_ID_SUCCESS = 'LISTS.GET_LISTS_BY_ID_SUCCESS';
export const GET_LISTS_BY_ID_FAILED = 'LISTS.GET_LISTS_BY_ID_FAILED';

export const GET_MORE_LISTS_BY_ID_REQUESTED =
  'LISTS.GET_MORE_LISTS_BY_ID_REQUESTED';
export const GET_MORE_LISTS_BY_ID_SUCCESS =
  'LISTS.MORE_GET_LISTS_BY_ID_SUCCESS';
export const GET_MORE_LISTS_BY_ID_FAILED = 'LISTS.GET_MORE_LISTS_BY_ID_FAILED';

export const CREATE_A_SHOPPING_LIST_REQUESTED =
  'LISTS.CREATE_A_SHOPPING_LIST_REQUESTED';
export const CREATE_A_SHOPPING_LIST_SUCCESS =
  'LISTS.CREATE_A_SHOPPING_LIST_SUCCESS';
export const CREATE_A_SHOPPING_LIST_FAILED =
  'LISTS.CREATE_A_SHOPPING_LIST_FAILED';

export const REMOVE_FROM_SHOPPING_LIST_LOOKUP_SUCCESS =
  'LIST_LOOKUP.REMOVE_FROM_SHOPPING_LIST_LOOKUP_SUCCESS';
export const REMOVE_FROM_SHOPPING_LIST_LOOKUP_FAILED =
  'LIST_LOOKUP.REMOVE_FROM_SHOPPING_LIST_LOOKUP_FAILED';

export const RENAME_SHOPPING_LIST_REQUESTED =
  'LISTS.RENAME_SHOPPING_LIST_REQUESTED';
export const RENAME_SHOPPING_LIST_SUCCESS =
  'LISTS.RENAME_SHOPPING_LIST_SUCCESS';
export const RENAME_SHOPPING_LIST_FAILED = 'LISTS.RENAME_SHOPPING_LIST_FAILED';

export const DELETE_SHOPPING_LIST_REQUESTED =
  'LISTS.DELETE_SHOPPING_LIST_REQUESTED';
export const DELETE_SHOPPING_LIST_SUCCESS =
  'LISTS.DELETE_SHOPPING_LIST_SUCCESS';
export const DELETE_SHOPPING_LIST_FAILED = 'LISTS.DELETE_SHOPPING_LIST_FAILED';

export const RESET_SHOPPING_LIST_SUCCESS = 'LISTS.RESET_SHOPPING_LIST_SUCCESS';

export const SET_ISMOBILE_SUCCESS = 'INIT.SET_ISMOBILE_SUCCESS';

export const GET_PBB_BANK_DETAILS_REQUESTED =
  'PBB.GET_PBB_BANK_DETAILS_REQUESTED';
export const GET_PBB_BANK_DETAILS_SUCCESS = 'PBB.GET_PBB_BANK_DETAILS_SUCCESS';
export const GET_PBB_BANK_DETAILS_FAILED = 'PBB.GET_PBB_BANK_DETAILS_FAILED';

export const SET_USE_DEFAULT_PBB_BANK_SUCCESS =
  'PBB.SET_USE_DEFAULT_PBB_BANK_SUCCESS';

export const SEND_REFUND_EMAIL_REQUESTED = 'EMAIL.SEND_REFUND_EMAIL_REQUESTED';
export const SEND_REFUND_EMAIL_SUCCESS = 'EMAIL.SEND_REFUND_EMAIL_SUCCESS';
export const SEND_REFUND_EMAIL_FAILED = 'EMAIL.SEND_REFUND_EMAIL_FAILED';
export const SEND_REFUND_EMAIL_RESET = 'EMAIL.SEND_REFUND_EMAIL_RESET';

export const GET_BLOG_CATEGORIES_HOME_INFO_REQUESTED =
  'BLOG.GET_BLOG_CATEGORIES_HOME_INFO_REQUESTED';
export const GET_BLOG_CATEGORIES_HOME_INFO_SUCCESS =
  'BLOG.GET_BLOG_CATEGORIES_HOME_INFO_SUCCESS';
export const GET_BLOG_CATEGORIES_HOME_INFO_FAILED =
  'BLOG.GET_BLOG_CATEGORIES_HOME_INFO_FAILED';

export const GET_BLOG_CATEGORY_SLUG_REQUESTED =
  'BLOG.GET_BLOG_CATEGORY_SLUG_REQUESTED';
export const GET_BLOG_CATEGORY_SLUG_SUCCESS =
  'BLOG.GET_BLOG_CATEGORY_SLUG_SUCCESS';
export const GET_BLOG_CATEGORY_SLUG_FAILED =
  'BLOG.GET_BLOG_CATEGORY_SLUG_FAILED';

export const GET_BLOG_POST_BY_ID_REQUESTED =
  'BLOG.GET_BLOG_POST_BY_ID_REQUESTED';
export const GET_BLOG_POST_BY_ID_SUCCESS = 'BLOG.GET_BLOG_POST_BY_ID_SUCCESS';
export const GET_BLOG_POST_BY_ID_FAILED = 'BLOG.GET_BLOG_POST_BY_ID_FAILED';

export const GET_BLOG_POST_BY_SLUG_REQUESTED =
  'BLOG.GET_BLOG_POST_BY_SLUG_REQUESTED';
export const GET_BLOG_POST_BY_SLUG_SUCCESS =
  'BLOG.GET_BLOG_POST_BY_SLUG_SUCCESS';
export const GET_BLOG_POST_BY_SLUG_FAILED = 'BLOG.GET_BLOG_POST_BY_SLUG_FAILED';
export const GET_BLOG_POST_BY_TITLE_REQUESTED =
  'BLOG.GET_BLOG_POST_BY_TITLE_REQUESTED';
export const GET_BLOG_POST_BY_TITLE_SUCCESS =
  'BLOG.GET_BLOG_POST_BY_TITLE_SUCCESS';
export const GET_BLOG_POST_BY_TITLE_FAILED =
  'BLOG.GET_BLOG_POST_BY_TITLE_FAILED';
export const GET_JJ_CONTENT_BY_SLUG_REQUESTED =
  'CONTENT.GET_JJ_CONTENT_BY_SLUG_REQUESTED';
export const GET_JJ_CONTENT_BY_SLUG_SUCCESS =
  'CONTENT.GET_JJ_CONTENT_BY_SLUG_SUCCESS';
export const GET_JJ_CONTENT_BY_SLUG_FAILED =
  'CONTENT.GET_JJ_CONTENT_BY_SLUG_FAILED';

export const GET_SHOP_BY_SECTORS_REQUESTED =
  'CONTENT.GET_SHOP_BY_SECTORS_REQUESTED';
export const GET_SHOP_BY_SECTORS_SUCCESS =
  'CONTENT.GET_SHOP_BY_SECTORS_SUCCESS';
export const GET_SHOP_BY_SECTORS_FAILED = 'CONTENT.GET_SHOP_BY_SECTORS_FAILED';
