import React from 'react';

import BranchLocatorPage from '../client/BranchLocatorPage/BranchLocatorPage';
import SurveyPage from '../client/SurveyPage/SurveyPage';
import OrdersPage from '../client/OrdersPage/OrdersPage';
import PasswordResetPage from '../client/PasswordResetPage/PasswordResetPage';
import AccountPage from '../client/AccountPage/AccountPage';
import PaymentCallbackPage from '../client/PaymentCallbackPage/PaymentCallbackPage';
import {NotFound} from '../client/NotFound/NotFound';
import HomePage from '../client/HomePage/HomePage';
import ConfirmationPage from '../client/ConfirmationPage/ConfirmationPage';
import FavouritesPage from '../client/FavouritesPage/FavouritesPage';
import CareersPage from '../client/CareersPage/CareersPage';
import BrochuresPage from '../client/Brochures/BrochuresPage';
import VacanciesPage from '../client/VacanciesPage/VacanciesPage';
import TeamPage from '../client/TeamPage/TeamPage';
import CustomersPage from '../client/CustomersPage/CustomersPage';
import HelpPage from '../client/HelpPage/HelpPage';
import PayByBankFAQPage from '../client/PayByBankFAQPage/PayByBankFAQPage';
import RegisterActivationPage from '../client/RegisterActivationPage/RegisterActivationPage';
import ProductDetailPage from '../client/ProductDetailPage/ProductDetailPage';
import DownloadPage from '../client/DownloadPage/DownloadPage';
import DeliveriesPage from '../client/DeliveriesPage/DeliveriesPage';
import {
  loadSearchData,
  loadProductDetailsData,
  loadBranchLocatorData,
  loadHomePageData,
  loadPackagingPageData,
  loadWixPost,
  loadWixCategories,
  loadWixPostsBySlug,
  loadStrapiContentPost,
} from './loadDataForRoutes';
import SearchPage from '../client/SearchPage/SearchPage';
import WhyJJPage from '../client/WhyJJPage/WhyJJPage';
import {
  BRAND_PATH,
  CATEGORY_LEVEL1_PATH,
  CATEGORY_LEVEL2_PATH,
  CATEGORY_LEVEL3_PATH,
  LOGIN_PATH,
  PAYMENT_CALLBACK_PATH,
  PRODUCT_DETAIL_PATH,
  REGISTER_PATH,
  SIGNUP_PATH,
  BRAINTREE_PATH,
  CATEGORY_ROOT_PATH,
  ECOSPEND_PAYMENT_PATH,
} from '../constants/routePaths';
import {BrowserNotSupported} from '../client/BrowserNotSupported/BrowserNotSupported';
import BraintreePage from '../client/BraintreePage/BraintreePage';
import PackagingPage from '../client/PackagingPage/PackagingPage';
import BlogCategoryPage from '../client/BlogPage/BlogCategoryPage';
import BlogPostPage from '../client/BlogPage/BlogPostPage';
import BlogCategoriesPage from '../client/BlogPage/BlogCategoriesPage';
import JJContentPage from '../client/JJContent/JJContentPage';
import {Redirect} from 'react-router-dom';
import {TERMS_AND_CONDITIONS_LINK} from '../constants/links';

export const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
    loadData: loadHomePageData,
  },
  {
    path: LOGIN_PATH,
    exact: true,
    component: HomePage,
  },
  {
    path: REGISTER_PATH,
    exact: true,
    component: HomePage,
  },
  {
    path: SIGNUP_PATH,
    exact: true,
    component: HomePage,
  },
  {
    path: PRODUCT_DETAIL_PATH,
    component: ProductDetailPage,
    loadData: loadProductDetailsData,
  },
  {
    path: BRAND_PATH,
    component: SearchPage,
    loadData: loadSearchData,
  },
  {
    path: CATEGORY_LEVEL3_PATH,
    component: SearchPage,
    loadData: loadSearchData,
  },
  {
    path: CATEGORY_LEVEL2_PATH,
    component: SearchPage,
    loadData: loadSearchData,
  },
  {
    path: CATEGORY_LEVEL1_PATH,
    component: SearchPage,
    loadData: loadSearchData,
  },
  {
    path: CATEGORY_ROOT_PATH,
    component: SearchPage,
    loadData: loadSearchData,
  },
  {
    path: '/recommended',
    component: HomePage,
  },
  {
    path: '/blog',
    exact: true,
    component: BlogCategoriesPage,
    loadData: loadWixCategories,
  },
  {
    path: '/blog/:slugName',
    exact: true,
    component: BlogCategoryPage,
    loadData: loadWixPostsBySlug,
  },
  {
    path: '/blog/:slugName/:postSlugName',
    exact: true,
    component: BlogPostPage,
    loadData: loadWixPost,
  },
  {
    path: '/content/:contentSlugName',
    exact: true,
    component: JJContentPage,
    loadData: loadStrapiContentPost,
  },
  {
    path: '/orders',
    component: OrdersPage,
  },
  {
    path: '/branch/:branchName',
    component: BranchLocatorPage,
    loadData: loadBranchLocatorData,
  },
  {
    path: '/branchLocator/:postcode',
    component: BranchLocatorPage,
    loadData: loadBranchLocatorData,
  },
  {
    path: '/branchLocator/',
    component: BranchLocatorPage,
    loadData: loadBranchLocatorData,
  },
  {
    path: '/team',
    component: TeamPage,
  },
  {
    path: '/help',
    component: HelpPage,
  },
  {
    path: '/paybybankfaq',
    component: PayByBankFAQPage,
  },
  {
    path: BRAINTREE_PATH,
    component: BraintreePage,
  },
  {
    path: '/customers',
    component: CustomersPage,
  },
  {
    path: PAYMENT_CALLBACK_PATH,
    component: PaymentCallbackPage,
  },
  {
    path: ECOSPEND_PAYMENT_PATH,
    component: PaymentCallbackPage,
  },
  {
    path: '/survey/:userId',
    component: SurveyPage,
  },
  {
    path: '/account',
    component: AccountPage,
  },
  {
    path: '/passwordReset/:code',
    component: PasswordResetPage,
  },
  {
    path: '/activateAccount/:code',
    component: RegisterActivationPage,
  },
  {
    path: '/confirmation/:axOrderId',
    component: ConfirmationPage,
  },
  {
    path: '/terms-and-conditions',
    exact: false,
    render: () => <Redirect to={TERMS_AND_CONDITIONS_LINK.url} />,
  },
  {
    path: '/terms',
    exact: false,
    render: () => <Redirect to={TERMS_AND_CONDITIONS_LINK.url} />,
  },
  {
    path: '/careers',
    component: CareersPage,
  },
  {
    path: '/vacancies',
    component: VacanciesPage,
  },
  {
    path: '/brochures',
    component: BrochuresPage,
  },
  {
    path: '/why-jj',
    component: WhyJJPage,
  },
  {
    path: '/deliveries',
    component: DeliveriesPage,
  },
  {
    path: '/download',
    component: DownloadPage,
  },
  {
    path: '/browser-not-supported',
    component: BrowserNotSupported,
  },
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '/favourites',
    component: FavouritesPage,
  },
  {
    path: [
      '/packaging',
      '/food-packaging',
      '/takeaway-packaging',
      '/takeaway-packaging-wholesales',
    ],
    component: PackagingPage,
    loadData: loadPackagingPageData,
  },
  {
    component: NotFound,
  },
];
