import {put, call, takeLatest, select} from 'redux-saga/effects';
import listApi from '../api/list.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {ERRORS} from '../constants/constants';

function* getShoppingLists(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const results = yield call(listApi.getLists, headers);
    if (results && results.errors) {
      yield put({
        type: ActionTypes.GET_ALL_LISTS_FAILED,
        message: results.errors[0].message,
      });
    }
    if (results && results.data && results.data.shoppingLists) {
      yield put({
        type: ActionTypes.GET_ALL_LISTS_SUCCESS,
        shoppingLists: results.data.shoppingLists,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.GET_ALL_LISTS_FAILED,
        message: e.message,
      });
    }
  }
}

function* getShoppingListById(action) {
  const isGetMore = action.page > 0;
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      shoppingListId: action.listId,
      page: action.page || 0,
    };
    const results = yield call(listApi.lookupList, params, headers);
    if (results && results.data && results.data.lookupShoppingList) {
      yield put({
        type: isGetMore
          ? ActionTypes.GET_MORE_LISTS_BY_ID_SUCCESS
          : ActionTypes.GET_LISTS_BY_ID_SUCCESS,
        shoppingListLookup: results.data.lookupShoppingList,
      });
    } else if (results && results.errors && results.errors.length > 0) {
      yield put({
        type: isGetMore
          ? ActionTypes.GET_MORE_LISTS_BY_ID_FAILED
          : ActionTypes.GET_LISTS_BY_ID_FAILED,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: isGetMore
          ? ActionTypes.GET_MORE_LISTS_BY_ID_FAILED
          : ActionTypes.GET_LISTS_BY_ID_FAILED,
        message: e.message,
      });
    }
  }
}

function* createAShoppingList(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      name: action.name,
    };
    const results = yield call(listApi.createList, params, headers);
    if (results && results.data && results.data.createShoppingList) {
      yield put({
        type: ActionTypes.CREATE_A_SHOPPING_LIST_SUCCESS,
        shoppingList: results.data.createShoppingList,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.CREATE_A_SHOPPING_LIST_FAILED,
        message: e.message,
      });
    }
  }
}

function* renameShoppingList(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      id: action.listId,
      name: action.updatedName,
    };
    const results = yield call(listApi.renameList, params, headers);
    if (results && results.data && results.data.renameShoppingList) {
      yield put({
        type: ActionTypes.RENAME_SHOPPING_LIST_SUCCESS,
        results: results.data.renameShoppingList,
      });
    } else if (results && results.errors && results.errors.length > 0) {
      yield put({type: ActionTypes.RENAME_SHOPPING_LIST_FAILED});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.RENAME_SHOPPING_LIST_FAILED,
        message: e.message,
      });
    }
  }
}

function* deleteShoppingList(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      id: action.listId,
    };
    const results = yield call(listApi.deleteList, params, headers);
    if (results && results.data && results.data.deleteShoppingList) {
      yield put({
        type: ActionTypes.DELETE_SHOPPING_LIST_SUCCESS,
        listId: action.listId,
      });
    } else if (results && results.errors && results.errors.length > 0) {
      yield put({type: ActionTypes.DELETE_SHOPPING_LIST_FAILED});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.DELETE_SHOPPING_LIST_FAILED,
        message: e.message,
      });
    }
  }
}

function* addItemToShoppingList(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      id: action.listId,
      itemId: action.itemId,
      quantity: action.quantity || 1,
    };
    const results = yield call(listApi.addToList, params, headers);
    if (results && results.data && results.data.addItemToShoppingList) {
      yield put({
        type: ActionTypes.ADD_TO_SHOPPING_LIST_SUCCESS,
        shoppingList: results.data.addItemToShoppingList,
        itemId: action.itemId,
      });
    } else {
      yield put({
        type: ActionTypes.ADD_TO_SHOPPING_LIST_FAILED,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.ADD_TO_SHOPPING_LIST_FAILED,
        message: e.message,
      });
    }
  }
}

function* removeItemFromShoppingList(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const params = {
      id: action.listId,
      itemId: action.itemId,
    };
    const results = yield call(listApi.removeFromList, params, headers);
    if (results && results.data && results.data.removeItemFromShoppingList) {
      yield put({
        type: ActionTypes.REMOVE_FROM_SHOPPING_LIST_SUCCESS,
        listId: action.listId,
        itemId: action.itemId,
      });
    }
    if (action.isLookupPage) {
      yield put({
        type: ActionTypes.REMOVE_FROM_SHOPPING_LIST_LOOKUP_SUCCESS,
        listId: action.listId,
        itemId: action.itemId,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.REMOVE_FROM_SHOPPING_LIST_FAILED,
        message: e.message,
      });
    }
  }
}

export default function* shoppingListsYield() {
  yield takeLatest(ActionTypes.GET_ALL_LISTS_REQUESTED, getShoppingLists);
  yield takeLatest(ActionTypes.GET_LISTS_BY_ID_REQUESTED, getShoppingListById);
  yield takeLatest(
    ActionTypes.GET_MORE_LISTS_BY_ID_REQUESTED,
    getShoppingListById
  );
  yield takeLatest(
    ActionTypes.CREATE_A_SHOPPING_LIST_REQUESTED,
    createAShoppingList
  );
  yield takeLatest(
    ActionTypes.ADD_TO_SHOPPING_LIST_REQUESTED,
    addItemToShoppingList
  );
  yield takeLatest(
    ActionTypes.REMOVE_FROM_SHOPPING_LIST_REQUESTED,
    removeItemFromShoppingList
  );
  yield takeLatest(
    ActionTypes.RENAME_SHOPPING_LIST_REQUESTED,
    renameShoppingList
  );
  yield takeLatest(
    ActionTypes.DELETE_SHOPPING_LIST_REQUESTED,
    deleteShoppingList
  );
}
