import initialState from './initial.state';
import * as types from '../actions/types.action';
import {REMOVE_LOGIN_AUTH_REQUESTED} from '../actions/types.action';

export default function loginTokenReducer(
  state = initialState.loginToken,
  action
) {
  switch (action.type) {
    case types.SET_LOGIN_TOKEN_SUCCESS:
      return action.loginToken;
    case types.REMOVE_LOGIN_AUTH_REQUESTED:
    case types.REMOVE_AUTH_REQUESTED:
      return null;
    default:
      return state;
  }
}
