import ReactGA from 'react-ga4';
import errorToJSON from 'error-to-json';
import Fingerprint2 from 'fingerprintjs2';

import {
  ANALYTICS_API_DELAY_TIME,
  ANALYTICS_DIMENSIONS,
  ANALYTICS_EC,
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENTS,
  JJ_DOMAIN,
  JJ_LOCAL_STORAGE,
  ROUTES,
  STOCK_CHECK,
} from '../constants/constants';
import analyticsApi from '../api/analytics.api';
import {getApiConfig} from '../config/configProvider';
import {getJJDeviceIdCookieValue} from './data.helper';
import TagManager from 'react-gtm-module';
import TiktokPixel from 'tiktok-pixel';

const appendDomain = url => {
  if (!url || typeof window === 'undefined') {
    return '';
  }
  const hostname =
    window.location.hostname === JJ_DOMAIN.PROD
      ? JJ_DOMAIN.PROD
      : JJ_DOMAIN.DEV;
  if (!url.includes(hostname)) {
    return hostname + url;
  }
  return url;
};

export class GoogleAnalyticsHelper {
  static FULLFILLMENT_DIMENSION = null;
  static USER_INFO = {};
  static INITIALIZED = false;
  static DEVICE_ID = null;
  static STORE = {};

  static initialize() {
    const config = getApiConfig();
    if (!config || !config.googleAnalytics || !config.googleAnalytics.enabled) {
      return;
    }

    ReactGA.initialize([
      {
        trackingId: config.googleAnalytics.trackingID,
      },
    ]);

    TagManager.initialize({
      gtmId: config.googleAnalytics.gtmID,
    });

    TiktokPixel.init(
      config.googleAnalytics.tiktokPixelId,
      {},
      {debug: config.googleAnalytics && config.googleAnalytics.debug}
    );

    this.DEVICE_ID = getJJDeviceIdCookieValue();

    if (window && !this.DEVICE_ID) {
      Fingerprint2.get(components => {
        const values = components.map(component => component.value);
        const generatedDeviceId = Fingerprint2.x64hash128(values.join(''), 31);
        this.DEVICE_ID = generatedDeviceId;
        this.USER_INFO.deviceHash = this.DEVICE_ID;
        document.cookie = JJ_LOCAL_STORAGE.DEVICE_ID + '=' + generatedDeviceId;
      });
    }
    this.INITIALIZED = true;
  }

  static updateStore = store => {
    this.STORE = Object.assign({}, this.STORE, store);
  };

  static setUserInfo(obj) {
    if (!this.INITIALIZED) {
      return;
    }
    const config = getApiConfig();
    this.USER_INFO = Object.assign({}, this.USER_INFO, obj);
    this.USER_INFO.deviceHash = this.DEVICE_ID;
    if (config.googleAnalytics && config.googleAnalytics.debug) {
      console.log('[Joacalytics] setUserInfo', obj, this.USER_INFO); // eslint-disable-line
    }
    ReactGA.set({
      customerType: this.USER_INFO.customerType,
    });
    if (obj.l_account) {
      ReactGA.set({
        user_id: obj.l_account,
      });
    }
  }

  static clearUserInfo() {
    this.USER_INFO = {};
  }

  static setDimension(branchID) {
    if (!this.INITIALIZED) {
      return;
    }
    ReactGA.set({[ANALYTICS_DIMENSIONS.BRANCH_CODE_DIMENSION]: branchID});
  }

  static setFulfillment(type) {
    if (!this.INITIALIZED) {
      return;
    }
    if (type !== this.FULLFILLMENT_DIMENSION) {
      ReactGA.set({[ANALYTICS_DIMENSIONS.FULLFILLMENT_DIMENSION]: type});
      this.FULLFILLMENT_DIMENSION = type;
    }
  }

  /**
   * Method that logs on Google Analytics the current page.
   * @returns void
   */
  static logPageView(url, time) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = time || new Date();
    const location =
      appendDomain(url) ||
      (typeof window !== 'undefined' &&
        window.location &&
        window.location.href);

    if (!location) {
      return;
    }

    if (this.DEVICE_ID) {
      analyticsApi.pageview(location, timeStamp, this.USER_INFO);
      if (location.indexOf('/' + ROUTES.BASKET) > 1) {
        const items = this.STORE.basket.items.map(product =>
          this.simplifyProduct(product)
        );
        const value = this.getTotalValue(items);
        ReactGA.event(ANALYTICS_EC.VIEW_CART, {
          currency: 'GBP',
          value,
          items,
        });
      } else if (location.indexOf('/' + ROUTES.PRODUCT) > 1) {
        const items = this.simplifyProduct(this.STORE.productDetail);
        const value = this.getTotalValue([items]);
        ReactGA.event(ANALYTICS_EC.VIEW_ITEM, {
          currency: 'GBP',
          value,
          items,
        });
      } else {
        ReactGA.send({hitType: 'pageview', page: location});
      }
      TiktokPixel.pageView();
    } else {
      const retryTimer = setTimeout(() => {
        this.logPageView(location, timeStamp);
      }, ANALYTICS_API_DELAY_TIME);
    }
  }

  static logError(description, error) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();

    ReactGA.ga(
      'send',
      'event',
      ANALYTICS_EVENT_CATEGORIES.JAVASCRIPT_ERROR,
      `Error - ${description}`,
      JSON.stringify(errorToJSON(error)),
      {nonInteraction: 1}
    );
    analyticsApi.errorEvent(
      ANALYTICS_EVENT_CATEGORIES.JAVASCRIPT_ERROR,
      description,
      error,
      timeStamp,
      this.USER_INFO
    );
  }

  static simplifyProduct(product, quantity, isDelivery) {
    if (!product) {
      return;
    }

    const price =
      (isDelivery
        ? (product.delivery && product.delivery.price) || product.price
        : (product.collection && product.collection.price) || product.price) ||
      product.priceIncVat;

    const namePrefix =
      product.stockCheck &&
      product.stockCheck.result === STOCK_CHECK.OUT_OF_STOCK
        ? 'Out of stock - '
        : '';
    const productName =
      product.itemName ||
      (product.product && product.product.itemName) ||
      product.itemId;

    return {
      item_id: product.itemId,
      item_name: namePrefix + productName,
      item_category: (
        product.categoryName ||
        (product.product && product.product.categoryName) ||
        ''
      ).replace(',', '/'),
      price,
      quantity: quantity || product.quantity,
      currency: 'GBP',
    };
  }

  static getTotalValue = simpleItems => {
    let value = 0;
    simpleItems.map(item => {
      if (item.price) {
        value += item.price * (item.quantity || 1);
      }
      return item;
    });
    return value;
  };

  static trackEvent(action, data) {
    if (!this.INITIALIZED) {
      return;
    }
    const config = getApiConfig();
    if (config.googleAnalytics && config.googleAnalytics.debug) {
      console.log('[Joacalytics]', action, data); // eslint-disable-line
    }
    const timeStamp = new Date();
    ReactGA.set({data});
    ReactGA.event({
      category: (data && data.category) || 'User',
      action,
    });
    analyticsApi.trackEvent(action, data, timeStamp, this.USER_INFO);
    TiktokPixel.track(action, data);
  }

  static trackCustomEvent(
    actionGA,
    dataGA,
    actionJoachlytics,
    dataJoachlytics
  ) {
    if (!this.INITIALIZED) {
      return;
    }
    const config = getApiConfig();
    if (config.googleAnalytics && config.googleAnalytics.debug) {
      console.log(
        '[Joacalytics]',
        actionGA,
        actionJoachlytics,
        dataGA,
        dataJoachlytics
      ); // eslint-disable-line
    }
    const timeStamp = new Date();
    ReactGA.set({data: dataGA});
    ReactGA.event({
      category: (dataGA && dataGA.category) || 'User',
      action: actionGA,
    });
    analyticsApi.trackEvent(
      actionJoachlytics,
      dataJoachlytics,
      timeStamp,
      this.USER_INFO
    );
    TiktokPixel.track(actionJoachlytics, dataJoachlytics);
  }

  static addProduct(product, quantity, source, isDelivery) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();

    const loggedProduct = this.simplifyProduct(product, quantity);

    ReactGA.event(ANALYTICS_EC.EC_ADD_PRODUCT, {
      currency: 'GBP',
      value: loggedProduct.price,
      items: [loggedProduct],
    });

    analyticsApi.addProduct(
      ANALYTICS_EVENTS.ADD_PRODUCT,
      loggedProduct,
      quantity,
      source,
      isDelivery,
      ANALYTICS_EVENTS.ADD_PRODUCT,
      timeStamp,
      this.USER_INFO
    );
    TiktokPixel.track(ANALYTICS_EVENTS.TIKTOK_ADD_TO_CART, {
      contents: [
        {
          content_id: product.itemId,
          content_name: product.itemName,
          quantity,
          price: loggedProduct.price,
        },
      ],
      content_type: 'product',
      currency: 'GBP',
      value: loggedProduct.price,
    });
  }

  static removeProduct(product) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();

    const loggedProduct = this.simplifyProduct(product);
    ReactGA.event(ANALYTICS_EC.EC_REMOVE_ACTION, {
      currency: 'GBP',
      value: loggedProduct.price,
      items: [loggedProduct],
    });

    analyticsApi.removeProduct(
      ANALYTICS_EVENTS.REMOVE_PRODUCT,
      loggedProduct,
      ANALYTICS_EC.EC_REMOVE_ACTION,
      timeStamp,
      this.USER_INFO
    );
  }

  static trackConfirmation(products, serviceType) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }

    const items = products.map(product => this.simplifyProduct(product));
    const value = this.getTotalValue(items);

    ReactGA.event(ANALYTICS_EC.EC_CHECKOUT, {
      currency: 'GBP',
      value,
      items,
    });
    const timeStamp = new Date();

    ReactGA.event({
      category: ANALYTICS_EVENT_CATEGORIES.SHOPPING_CART,
      action: ANALYTICS_EVENTS.CONFIRM_ORDER,
    });

    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.CONFIRM_ORDER,
      {
        products,
        serviceType,
      },
      timeStamp,
      this.USER_INFO
    );
  }

  static trackPayment(products, paymentType) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const items = products.map(product => this.simplifyProduct(product));
    const value = this.getTotalValue(items);

    ReactGA.event(ANALYTICS_EC.EC_ADD_PAYMENT_INFO, {
      currency: 'GBP',
      value,
      payment_type: paymentType,
      items,
    });

    ReactGA.event({
      category: ANALYTICS_EVENT_CATEGORIES.SHOPPING_CART,
      action: ANALYTICS_EVENTS.START_PAYMENT,
    });
  }

  static trackSuccess(products, purchaseInfo) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }

    purchaseInfo.currency = 'GBP';
    purchaseInfo.tax = '0.00';
    purchaseInfo.affiliation = '';
    const timeStamp = new Date();

    const items = products.map(product => this.simplifyProduct(product));
    const value = this.getTotalValue(items);

    ReactGA.event(ANALYTICS_EC.EC_PURCHASE, {
      currency: 'GBP',
      transaction_id: purchaseInfo.axBasketId,
      value,
      items,
    });

    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.PURCHASE,
      {
        products,
        purchaseInfo,
      },
      timeStamp,
      this.USER_INFO
    );

    TiktokPixel.track(ANALYTICS_EVENTS.TIKTOK_PURCHASE, {
      contents: products.map(product => {
        return {
          content_id: product.itemId,
          content_name: product.itemName,
          quantity: product.quantity,
          price: product.price,
        };
      }),
      content_type: 'product',
      currency: 'GBP',
      value,
    });
  }

  static trackFailed(products, purchaseInfo) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    purchaseInfo.currency = 'GBP';
    purchaseInfo.tax = '0.00';
    purchaseInfo.affiliation = '';
    const timeStamp = new Date();

    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.PURCHASE_FAILED,
      {
        products,
        purchaseInfo,
      },
      timeStamp,
      this.USER_INFO
    );
  }

  static trackFeedback(cAccount, rating) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();
    ReactGA.ga('send', ANALYTICS_EVENTS.RATINGS_FEEDBACK, {
      timeStamp,
      rating,
      cAccount,
    });
    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.RATINGS_FEEDBACK,
      {
        cAccount,
        rating,
      },
      timeStamp,
      this.USER_INFO
    );
  }

  static trackBraintreeError(error) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();
    const data = JSON.stringify(error);
    ReactGA.ga('send', ANALYTICS_EVENTS.BRAINTREE_ERROR, {
      timeStamp,
      data,
    });
    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.BRAINTREE_ERROR,
      {
        data,
      },
      timeStamp,
      this.USER_INFO
    );
  }

  static requestRefund(products, accountDetails, axBasketId) {
    const items = products.map(product => this.simplifyProduct(product));
    const value = this.getTotalValue(items);

    ReactGA.event(ANALYTICS_EC.EC_REFUND, {
      currency: 'GBP',
      transaction_id: axBasketId,
      value,
      items,
    });
  }

  static getAnalyticsTopBannerEventName(index) {
    return `Home_Sliding_Banner_${index + 1}`;
  }

  static getAnalyticsMarketingGridEventName(index, isMain) {
    return isMain ? `Home_Large_Block_${index}` : `Home_Small_Block_${index}`;
  }

  static trackAdyenPayment(result) {
    const config = getApiConfig();
    if (
      !config ||
      !config.googleAnalytics ||
      !config.googleAnalytics.enabled ||
      !this.INITIALIZED
    ) {
      return;
    }
    const timeStamp = new Date();
    const data = JSON.stringify(result);
    analyticsApi.trackEvent(
      ANALYTICS_EVENTS.ADYEN_EVENTS,
      {
        data,
      },
      timeStamp,
      this.USER_INFO
    );
  }
}
