import {put, call, takeLatest, select, delay, retry} from 'redux-saga/effects';
import queryString from 'query-string';
import {captureException, withScope} from '@sentry/browser';
import jwt_decode from 'jwt-decode';

import accountsApi from '../api/account.api';
import emailApi from '../api/email.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {ERRORS, JJ_LOCAL_STORAGE, RETRY_TIMEOUT} from '../constants/constants';
import {getPartAccountsDetails} from '../helpers/data.helper';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
  removeValueFromLocalStorage,
} from '../helpers/localStorage.helper';
import {containStaffAccount} from '../helpers/accounts.helper';
import {getApiConfig} from '../config/configProvider';

function* getAccounts(action) {
  try {
    const getAuth = state => state.auth;
    const getLoginToken = state => state.loginToken;
    const loginToken = yield select(getLoginToken);
    let storeToken = yield select(getAuth);
    if (!storeToken) {
      storeToken = action.jjToken || loginToken;
    }
    let headers = getBearerHeader(action.jjToken, storeToken);
    let isStaffAccount = false;
    let accounts;
    // search via staff account is using `tradingAccountId` for selecting account
    const isSelectingTradingAccount = !!action.tradingAccountId;
    const storedAccounts = yield getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.ACCOUNTS
    );
    let loginEmail = '';
    const config = getApiConfig();
    let appAdId = config.azureAdClientId;
    if (loginToken) {
      const loginTokenJwt = jwt_decode(loginToken);
      isStaffAccount = loginTokenJwt.appid === appAdId;
      const loginTokenObj =
        typeof loginToken === 'string' ? loginTokenJwt : loginToken;
      loginEmail = loginTokenObj.email;
    }
    if (!loginEmail) {
      loginEmail = storeToken.email;
    }
    if (!isStaffAccount && storeToken && storeToken.access_token) {
      isStaffAccount = containStaffAccount(storeToken);
    }
    if (isStaffAccount) {
      yield put({
        type: ActionTypes.SET_STAFF_ACCOUNT_SUCCESS,
        isStaffAccount,
      });
    }
    if (
      isStaffAccount &&
      !!storeToken &&
      (storeToken.c_account || isSelectingTradingAccount)
    ) {
      const accountId = action.tradingAccountId
        ? action.tradingAccountId
        : storeToken.c_account;
      // only gets account after staff chose an account and refreshed
      accounts = yield call(accountsApi.getAccountById, accountId, headers);
    } else {
      accounts = yield call(accountsApi.getAccounts, headers);
    }
    if (isSelectingTradingAccount && accounts && accounts.length === 1) {
      yield put({type: ActionTypes.SET_PROFILE_SUCCESS, account: accounts[0]});
      return;
    }
    if (accounts && accounts.length > 0) {
      yield put({
        type: action.fromCache
          ? ActionTypes.GET_ACCOUNTS_FROM_CACHE_SUCCESS
          : ActionTypes.GET_ACCOUNTS_SUCCESS,
        accounts,
      });
      // save the mini data required
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.ACCOUNTS, {
        [loginEmail]: getPartAccountsDetails(accounts),
      });
      return;
    }

    if (!isStaffAccount) {
      if (storedAccounts && storedAccounts[storeToken.email]) {
        yield put({
          type: ActionTypes.GET_ACCOUNTS_FROM_CACHE_SUCCESS,
          accounts: storedAccounts[storeToken.email],
        });
        return;
      }
      // only errors if no accounts return from api or localStorage
      yield put({type: ActionTypes.NO_ACTIVE_ACCOUNTS});
    } else if (accounts) {
      // do nothing if staff account has no accounts available
      // (account will be set when staff select one from account search result)
      yield put({
        type: ActionTypes.GET_ACCOUNTS_FROM_CACHE_SUCCESS,
        accounts,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({type: ActionTypes.GET_ACCOUNTS_FAILED, message: e.message});
    }
  }
}

function* setAccounts(action) {
  try {
    const getLoginToken = state => state.loginToken;
    const getAuth = state => state.auth;
    const authToken = yield select(getAuth);
    let storedToken;
    let loginToken = yield select(getLoginToken);
    if (!loginToken) {
      loginToken = yield getDataFromLocalStorage(JJ_LOCAL_STORAGE.LOGIN_TOKEN);
    }
    if (!authToken) {
      storedToken = yield getDataFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN);
    }
    const cachedToken = !!authToken ? authToken.access_token : storedToken;
    const access_token = cachedToken || loginToken;
    if (!access_token) {
      yield put({
        type: ActionTypes.SET_ACCOUNTS_FAILED,
        message: 'no tokens found',
      });
      return;
    }
    const data = {
      access_token: action.isStaffAccount ? loginToken : access_token,
      grant_type: 'enhance_token',
      account: action.account,
    };
    const MAX_TRY_TIME = 5;
    const enhancedToken = yield retry(
      MAX_TRY_TIME,
      RETRY_TIMEOUT,
      accountsApi.setAccounts,
      queryString.stringify(data)
    );
    if (enhancedToken && enhancedToken.access_token) {
      const {access_token} = enhancedToken;
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.TOKEN, access_token);
      const decodeToken = jwt_decode(access_token);
      persistDataToLocalStorage(
        JJ_LOCAL_STORAGE.C_ACCOUNT,
        decodeToken.c_account
      );
      yield put({
        type: ActionTypes.SET_ACCOUNTS_SUCCESS,
        access_token,
      });
      if (!action.isStaffAccount) {
        yield put({type: ActionTypes.REMOVE_LOGIN_AUTH_REQUESTED});
        removeValueFromLocalStorage(JJ_LOCAL_STORAGE.LOGIN_TOKEN);
      }
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      //todo better solution to warn user enhanced token failed
      yield put({type: ActionTypes.SET_ACCOUNTS_FAILED, message: e.message});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({type: ActionTypes.ENHANCE_TOKEN_FAILED});
      yield put({type: ActionTypes.SET_ACCOUNTS_FAILED, message: e.message});
    }
  }
}

function* searchAccounts(action) {
  try {
    yield delay(500);
    const getAuth = state => state.auth;
    const getLoginToken = state => state.loginToken;
    const loginToken = yield select(getLoginToken);
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(loginToken, storeToken);
    const accounts = yield call(
      accountsApi.searchAccounts,
      action.keywords,
      headers
    );
    if (accounts && accounts._embedded && accounts._embedded.accounts) {
      yield put({
        type: ActionTypes.GET_ACCOUNTS_SUCCESS,
        accounts: accounts._embedded.accounts,
      });
    } else if (accounts && accounts.page && accounts.page.totalElements === 0) {
      yield put({
        type: ActionTypes.GET_ACCOUNTS_SUCCESS,
        accounts: [],
      });
    } else {
      yield put({type: ActionTypes.GET_ACCOUNTS_FAILED});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({type: ActionTypes.SEARCH_ACCOUNTS_FAILED, message: e.message});
    }
  }
}

function* registerUser(action) {
  try {
    if (!action.payload) {
      return;
    }
    const messages = yield call(accountsApi.register, action.payload);
    yield put({
      type: ActionTypes.REGISTER_USER_SUCCESS,
      messages,
      payload: action.payload,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.REGISTER_USER_FAILED,
      message: e.message,
      payload: action.payload,
    });
  }
}

function* resetPassword(action) {
  try {
    if (!action.email) {
      return;
    }
    const messages = yield call(accountsApi.sendResetEmail, action.email);
    yield put({type: ActionTypes.PASSWORD_RESET_SUCCESS, messages});
  } catch (e) {
    yield put({
      type: ActionTypes.PASSWORD_RESET_FAILED,
      message: e.message,
    });
  }
}

function* setNewPassword(action) {
  try {
    if (!(action.payload.resetCode && action.payload.password1)) {
      return;
    }
    const messages = yield call(
      accountsApi.passwordReset,
      action.payload.resetCode,
      action.payload.password1
    );
    yield put({type: ActionTypes.SET_NEW_PASSWORD_SUCCESS, messages});
  } catch (e) {
    yield put({
      type: ActionTypes.SET_NEW_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

function* changePassword(action) {
  try {
    if (!(action.payload.oldPassword && action.payload.password1)) {
      return;
    }
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const messages = yield call(
      accountsApi.changePassword,
      storeToken.email,
      action.payload.oldPassword,
      action.payload.password1
    );
    const hasMessageData = messages && messages.data;
    if (
      hasMessageData &&
      messages.data.Data &&
      messages.data.Data.MessageList &&
      messages.data.Data.MessageList[0]
    ) {
      yield put({
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        messages: messages.data.Data.MessageList[0],
      });
    }
    if (hasMessageData && messages.data.Error && messages.data.Error.Message) {
      yield put({
        type: ActionTypes.CHANGE_PASSWORD_FAILED,
        message: e.message,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_FAILED,
      message: e.message,
    });
  }
}

function* activateAccount(action) {
  try {
    const messages = yield call(accountsApi.activateAccount, action.payload);
    const hasMessageData = messages && messages.data;
    if (
      hasMessageData &&
      messages.data.Data &&
      messages.data.Data.MessageList &&
      messages.data.Data.MessageList[0]
    ) {
      yield put({
        type: ActionTypes.ACTIVATE_ACCOUNT_SUCCESS,
        messages: messages.data.Data.MessageList[0],
      });
    }
    if (hasMessageData && messages.data.Error && messages.data.Error.Message) {
      yield put({
        type: ActionTypes.ACTIVATE_ACCOUNT_FAILED,
        message: messages.data.Error.Message,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.ACTIVATE_ACCOUNT_FAILED,
      message: e.message || null,
    });
  }
}

function* contactCS(action) {
  try {
    const result = yield call(emailApi.contactCS, action.payload);
    if (result) {
      yield put({
        type: ActionTypes.CONTACT_CUSTOMER_SERVICE_SUCCESS,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.CONTACT_CUSTOMER_SERVICE_FAILED,
    });
    // shows nothing on UI but capture on sentry
    withScope(scope => {
      scope.setExtra(
        'contactCustomerService',
        ActionTypes.CONTACT_CUSTOMER_SERVICE_FAILED
      );
      scope.setExtra('action', action);
      captureException(e);
    });
  }
}

function* refreshAXAccount(action) {
  try {
    if (!action.accountId) {
      return;
    }
    const getAuth = state => state.auth;
    const getLoginToken = state => state.loginToken;
    const loginToken = yield select(getLoginToken);
    let storeToken = yield select(getAuth);
    if (!storeToken) {
      storeToken = action.jjToken || loginToken;
    }
    let headers = getBearerHeader(action.jjToken, storeToken);
    const account = yield call(
      accountsApi.refreshAxAccountById,
      action.accountId,
      headers
    );
    if (account) {
      const updatedAccounts = yield call(
        accountsApi.getAccountById,
        action.accountId,
        headers
      );
      if (updatedAccounts && updatedAccounts[0]) {
        yield put({
          type: ActionTypes.REFRESH_AX_ACCOUNT_SUCCESS,
          accounts: updatedAccounts[0],
        });
      } else {
        yield put({type: ActionTypes.REFRESH_AX_ACCOUNT_FAILED});
      }
    } else {
      yield put({type: ActionTypes.REFRESH_AX_ACCOUNT_FAILED});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.REFRESH_AX_ACCOUNT_FAILED,
      message: e.message,
    });
  }
}

function* getPaymentDetails(action) {
  try {
    const getAuth = state => state.auth;
    const getLoginToken = state => state.loginToken;
    const loginToken = yield select(getLoginToken);
    let storeToken = yield select(getAuth);
    if (!storeToken) {
      storeToken = action.jjToken || loginToken;
    }
    let headers = getBearerHeader(action.jjToken, storeToken);
    const paymentDetails = yield call(accountsApi.getPaymentDetails, headers);

    yield put({
      type: ActionTypes.GET_PAYMENT_DETAILS_SUCCESS,
      paymentDetails,
    });
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.GET_PAYMENT_DETAILS_FAILED,
        message: e.message,
      });
    }
  }
}

function* setPaymentDetails(action) {
  try {
    const getAuth = state => state.auth;
    const getLoginToken = state => state.loginToken;
    const loginToken = yield select(getLoginToken);
    let storeToken = yield select(getAuth);
    if (!storeToken) {
      storeToken = action.jjToken || loginToken;
    }
    let headers = getBearerHeader(action.jjToken, storeToken);
    const paymentDetails = yield call(
      accountsApi.setPaymentDetails,
      action.paymentDetails,
      headers
    );

    yield put({
      type: ActionTypes.SET_PAYMENT_DETAILS_SUCCESS,
      paymentDetails,
    });
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else {
      yield put({
        type: ActionTypes.SET_PAYMENT_DETAILS_FAILED,
        message: e.message,
      });
    }
  }
}

export default function* accountYield() {
  yield takeLatest(ActionTypes.GET_ACCOUNTS_REQUESTED, getAccounts);
  yield takeLatest(ActionTypes.SET_ACCOUNTS_REQUESTED, setAccounts);
  yield takeLatest(ActionTypes.SEARCH_ACCOUNTS_REQUESTED, searchAccounts);
  yield takeLatest(ActionTypes.REGISTER_USER_REQUESTED, registerUser);
  yield takeLatest(ActionTypes.PASSWORD_RESET_REQUESTED, resetPassword);
  yield takeLatest(ActionTypes.SET_NEW_PASSWORD_REQUESTED, setNewPassword);
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_REQUESTED, changePassword);
  yield takeLatest(ActionTypes.ACTIVATE_ACCOUNT_REQUESTED, activateAccount);
  yield takeLatest(ActionTypes.CONTACT_CUSTOMER_SERVICE_REQUESTED, contactCS);
  yield takeLatest(ActionTypes.REFRESH_AX_ACCOUNT_REQUESTED, refreshAXAccount);
  yield takeLatest(
    ActionTypes.GET_PAYMENT_DETAILS_REQUESTED,
    getPaymentDetails
  );
  yield takeLatest(
    ActionTypes.SET_PAYMENT_DETAILS_REQUESTED,
    setPaymentDetails
  );
}
