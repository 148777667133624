import {createStore, applyMiddleware, compose} from 'redux';
import {captureException, setTag} from '@sentry/browser';
import createSagaMiddleware, {END} from 'redux-saga';
import * as Sentry from '@sentry/react';
import rootReducer from '../reducers/index.reducer';
import saga from '../saga/saga';

const onError = error => {
  setTag('environment', 'production');
  captureException(JSON.stringify(error), 'Store createSagaMiddleware error');
};

const sagaMiddleware = createSagaMiddleware({onError});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => action,
});
const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
  );
  sagaMiddleware.run(saga);
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
};

export default configureStore;
